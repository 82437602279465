/**
 * @author Mathias MANOU
 * @email mathiasmanou@gmail.com
 * @description Formations certifiantes page
 */

import { Text, Flex, Link, Image } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";

import AnimatedPage from "../components/AnimatedPage";

const FormationsCertifiantes = () => {
  return (
    <AnimatedPage>
      <Flex flexDirection={"column"}>
        <Flex flexDirection={"column"} alignItems={"center"}>
          <Text color={"brand.100"} pb={"1.5em"} fontSize={"1.3em"}>
            Notre cercle vertueux de la co-construction d’un parcours
            pédagogique certifiant sur-mesure :
          </Text>
          <Image
            width={900}
            borderRadius={"25"}
            src="https://firebasestorage.googleapis.com/v0/b/academie-c1882.firebasestorage.app/o/Capture%20d%E2%80%99e%CC%81cran%202024-12-10%20115809.jpg?alt=media&token=23f49207-f868-44aa-ae4c-12156de209b9"
            // src="https://firebasestorage.googleapis.com/v0/b/academie-c1882.firebasestorage.app/o/Cerclevertueuxformationscertifiantes.jpg?alt=media&token=39c4db4e-b5a0-4053-b15d-2e36bb9643bd"
            alt="Parcours pédagogique"
          />
        </Flex>
        <Flex flexDirection={"column"} alignItems={"center"}>
          <Text
            pt={"2.5em"}
            pb={"1.5em"}
            color={"brand.100"}
            fontSize={"1.3em"}
          >
            Les métiers pour lesquels nous participons à l’actualisation et au
            développement des compétences professionnelles pour atteindre vos
            objectifs de performance :
          </Text>
          <Image
            width={900}
            borderRadius={"25"}
            src="https://firebasestorage.googleapis.com/v0/b/academie-c1882.firebasestorage.app/o/Capture%20d%E2%80%99e%CC%81cran%202024-12-10%20115830.jpg?alt=media&token=9df8eae4-2980-4bfb-8a21-ace3039f280a"
            // src="https://firebasestorage.googleapis.com/v0/b/academie-c1882.firebasestorage.app/o/metiers.jpg?alt=media&token=b210c6fa-11c7-4e25-8d06-735c0c0706e0"
            alt="Les métiers"
          />
        </Flex>
        <Flex flexDirection={"column"} alignItems={"center"}>
          <Text
            pt={"2.5em"}
            pb={"1.5em"}
            color={"brand.100"}
            fontSize={"1.3em"}
          >
            Les thématiques sur lesquelles nos spécialistes interviennent à vos
            côtés :
          </Text>
          <Image
            width={900}
            borderRadius={"25"}
            src="https://firebasestorage.googleapis.com/v0/b/academie-c1882.firebasestorage.app/o/AIB_The%CC%81matiques%20pour%20site_bonne%20couleur_27.01.2025.jpg?alt=media&token=f0c61177-15b1-423f-8a95-172dd79431ea"
            // src="https://firebasestorage.googleapis.com/v0/b/academie-c1882.firebasestorage.app/o/Capture%20d%E2%80%99e%CC%81cran%202024-12-10%20115851.jpg?alt=media&token=415a27f4-7d14-4e09-976e-01bb875f31e4"
            // src="https://firebasestorage.googleapis.com/v0/b/academie-c1882.firebasestorage.app/o/thematiques.jpg?alt=media&token=0f95519f-1cd8-405c-8f89-0ec47edfbbc3"
            alt="Les thématiques"
          />
          <Text color={"brand.100"} pt={"2.5em"} fontSize={"1.3em"}>
            Catalyseur des compétences professionnelles de vos collaborateurs
            ainsi que de la performance de votre Etablissement, nous sommes
            ainsi à votre entière disposition afin de :<br></br>
            <br></br>- Clarifier plus avant votre besoin et le qualifier avec
            vous d’un point de vue pédagogique<br></br>
            <br></br>- Identifier finement à vos côtés les compétences
            professionnelles sur lesquelles vous souhaiteriez nous voir
            intervenir<br></br>
            <br></br>- Définir les modalités pédagogiques souhaitées
            (présentiel, distanciel, hybride) ainsi que le format du séminaire
            ad hoc (durée, planification)<br></br>
            <br></br>- Co-construire la solution sur mesure à vos problématiques
            <br></br>
            <br></br>A cet effet, nous vous invitons à{" "}
            <Link as={ReactRouterLink} to="/contact" color={"brand.200"}>
              nous contacter sans plus attendre
            </Link>
          </Text>
        </Flex>
      </Flex>
    </AnimatedPage>
  );
};

export default FormationsCertifiantes;
